import React from 'react';
import PropTypes from 'prop-types';
import { IconStyled } from './styles';
import {
  IconDots,
  IconDownload,
  IconMagnifier,
  IconFunnel,
  IconCross,
  IconFacebook,
  IconInstagram,
  IconYouTube,
  IconX,
  IconPatreon,
  IconFlagDe,
  IconFlagAt,
  IconFlagCh,
  IconFlagLie,
  IconFlagLux,
} from './icons';
import IconTiktok from './icons/IconTiktok';

const mapNameToComponent = {
  dots: <IconDots />,
  download: <IconDownload />,
  magnifier: <IconMagnifier />,
  funnel: <IconFunnel />,
  cross: <IconCross />,
  facebook: <IconFacebook />,
  instagram: <IconInstagram />,
  tiktok: <IconTiktok />,
  patreon: <IconPatreon />,
  youtube: <IconYouTube />,
  twitter: <IconX />,
  flag_de: <IconFlagDe />,
  flag_at: <IconFlagAt />,
  flag_ch: <IconFlagCh />,
  flag_lie: <IconFlagLie />,
  flag_lux: <IconFlagLux />,
};

const Icon = ({ name, className }) => {
  const icon = name in mapNameToComponent ? mapNameToComponent[name] : null;

  return icon && <IconStyled className={className}>{icon}</IconStyled>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Icon;
